var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 mt-2"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('h2', {
    staticClass: "title text-center pt-1 mt-1"
  }, [_vm._v("AppStore Notification")]), _c('br'), _vm.isError ? _c('b-row', {
    staticClass: "justify-content-center mt-2 mx-3"
  }, [_c('b-col', {
    attrs: {
      "lg": "10"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": "",
      "dismissible": ""
    }
  }, [_vm._v(" Opps .. Something is wrong ")])], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row justify-content-start mb-2"
  }, [_c('span', {
    staticClass: "col-sm-2"
  }, [_vm._v("Search Keyword")]), _c('div', {
    staticClass: "col-sm-4"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.keyword,
      expression: "keyword"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "original transaction OR transaction id..."
    },
    domProps: {
      "value": _vm.keyword
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.keyword = $event.target.value;
      }, _vm.debounceSearch]
    }
  }), _vm.typing ? _c('span', [_c('em', [_vm._v(" searching... ")])]) : _vm._e()])]), _c('div', {
    staticClass: "row justify-content-start mb-2 form-inline"
  }, [_c('span', {
    staticClass: "col-sm-2"
  }, [_vm._v("Select Range")]), _c('div', {
    staticClass: "col-sm-6"
  }, [_c('v-date-picker', {
    attrs: {
      "mode": "date",
      "masks": _vm.masks,
      "is-range": ""
    },
    on: {
      "input": _vm.dateOnChange
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var inputValue = _ref.inputValue,
          inputEvents = _ref.inputEvents,
          isDragging = _ref.isDragging;
        return [_c('input', _vm._g({
          staticClass: "form-control mr-1",
          class: isDragging ? 'text-gray-600' : 'text-gray-900',
          attrs: {
            "id": "startPeriod"
          },
          domProps: {
            "value": inputValue.start
          }
        }, inputEvents.start)), _c('input', _vm._g({
          staticClass: "form-control",
          class: isDragging ? 'text-gray-600' : 'text-gray-900',
          attrs: {
            "id": "endPeriod",
            "max-date": new Date()
          },
          domProps: {
            "value": inputValue.end
          }
        }, inputEvents.end))];
      }
    }]),
    model: {
      value: _vm.range,
      callback: function ($$v) {
        _vm.range = $$v;
      },
      expression: "range"
    }
  })], 1)]), _c('div', {
    staticClass: "row justify-content-start mb-2"
  }, [_c('span', {
    staticClass: "col-sm-2"
  }, [_vm._v("Select Notification Type")]), _c('div', {
    staticClass: "col-sm-4"
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.notificationTypeOptions,
      "required": ""
    },
    on: {
      "change": _vm.notifTypeOnChange
    },
    model: {
      value: _vm.notification_type,
      callback: function ($$v) {
        _vm.notification_type = $$v;
      },
      expression: "notification_type"
    }
  })], 1)]), _c('div', {
    staticClass: "row justify-content-end"
  }, [_c('label', {
    staticClass: "col-sm-auto",
    attrs: {
      "for": ""
    }
  }, [_vm._v("Total : ")]), _c('strong', {
    staticClass: "col-sm-auto"
  }, [_vm._v(_vm._s(_vm.formatNumber(_vm.totalRows)))])]), _c('b-table', {
    attrs: {
      "sticky-header": "500px",
      "items": _vm.items,
      "fields": _vm.fields,
      "busy": _vm.isLoading,
      "isLoading": _vm.isLoading,
      "show-empty": "",
      "responsive": "xl",
      "hover": "",
      "sort-icon-left": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + (row.index + 1)) + ". ")];
      }
    }, {
      key: "cell(product_id)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "350px"
          }
        }, [_vm._v(" " + _vm._s(row.item.product_id ? row.item.product_id : '-') + " ")])];
      }
    }, {
      key: "cell(environment)",
      fn: function (row) {
        return [row.item.environment == 'Production' ? _c('div', {
          staticStyle: {
            "width": "150px"
          }
        }, [_c('span', {
          staticClass: "badge badge-pill bg-primary"
        }, [_vm._v(_vm._s(row.item.environment ? row.item.environment : '-'))])]) : row.item.environment == 'Sandbox' ? _c('div', {
          staticStyle: {
            "width": "150px"
          }
        }, [_c('span', {
          staticClass: "badge badge-pill bg-warning"
        }, [_vm._v(_vm._s(row.item.environment ? row.item.environment : '-'))])]) : _c('div', [_vm._v("-")])];
      }
    }, {
      key: "cell(notification_type)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "180px"
          }
        }, [!row.item.notification_type ? _c('div', [_vm._v("-")]) : _c('div', [_c('notificationTypeVue', {
          attrs: {
            "notif": row.item.notification_type
          }
        })], 1)])];
      }
    }, {
      key: "cell(original_transaction_id)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "200px"
          }
        }, [_vm._v(" " + _vm._s(row.item.original_transaction_id ? row.item.original_transaction_id : '-') + " ")])];
      }
    }, {
      key: "cell(transaction_id)",
      fn: function (row) {
        var _row$item, _row$item2, _row$item3;
        return [(_row$item = row.item) !== null && _row$item !== void 0 && _row$item.transaction_id ? _c('div', {
          staticStyle: {
            "width": "200px",
            "cursor": "pointer",
            "text-decoration": "none"
          }
        }, [_c('router-link', {
          attrs: {
            "to": {
              path: '/appstore-notification/' + ((_row$item2 = row.item) === null || _row$item2 === void 0 ? void 0 : _row$item2.transaction_id)
            },
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s((_row$item3 = row.item) === null || _row$item3 === void 0 ? void 0 : _row$item3.transaction_id) + " ")])], 1) : _c('div', [_vm._v("-")])];
      }
    }, {
      key: "cell(purchase_date)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "250px"
          }
        }, [_vm._v(" " + _vm._s(row.item.purchase_date ? _vm.formatDate(+row.item.purchase_date) : '-') + " ")])];
      }
    }, {
      key: "cell(revocation_date)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "250px"
          }
        }, [_vm._v(" " + _vm._s(row.item.revocation_date && row.item.revocation_date != 0 ? _vm.formatDate(+row.item.revocation_date) : '-') + " ")])];
      }
    }, {
      key: "cell(created)",
      fn: function (row) {
        var _row$item4, _row$item5;
        return [_c('div', {
          staticStyle: {
            "width": "250px"
          }
        }, [_vm._v(" " + _vm._s((_row$item4 = row.item) !== null && _row$item4 !== void 0 && _row$item4.created ? _vm.formatDate((_row$item5 = row.item) === null || _row$item5 === void 0 ? void 0 : _row$item5.created) : '-') + " ")])];
      }
    }, {
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center text-dark my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle",
          attrs: {
            "variant": "dark",
            "type": "grow",
            "label": "Loading .."
          }
        }), _vm._v(" Loading .. ")], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-row', {
    staticClass: "justify-content-start ml-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }