var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-black"
  }, [_vm.notif == 'EXPIRED' ? _c('span', {
    staticClass: "badge badge-pill"
  }, [_c('span', {
    staticClass: "badge badge-pill bg-secondary"
  }, [_vm._v(" EXPIRED ")])]) : _vm._e(), _vm.notif == 'DID_RENEW' ? _c('span', {
    staticClass: "badge badge-pill"
  }, [_c('span', {
    staticClass: "badge badge-pill bg-info"
  }, [_vm._v(" DID RENEW ")])]) : _vm._e(), _vm.notif == 'DID_CHANGE_RENEWAL_STATUS' ? _c('span', {
    staticClass: "badge badge-pill"
  }, [_c('span', {
    staticClass: "badge badge-pill bg-warning"
  }, [_vm._v(" DID CHANGE RENEWAL STATUS ")])]) : _vm._e(), _vm.notif == 'DID_FAIL_TO_RENEW' ? _c('span', {
    staticClass: "badge badge-pill"
  }, [_c('span', {
    staticClass: "badge badge-pill bg-danger text-light"
  }, [_vm._v(" DID FAIL TO RENEW ")])]) : _vm._e(), _vm.notif == 'DID_CHANGE_RENEWAL_PREF' ? _c('span', {
    staticClass: "badge badge-pill"
  }, [_c('span', {
    staticClass: "badge badge-pill bg-orange"
  }, [_vm._v(" DID CHANGE RENEWAL PREF ")])]) : _vm._e(), _vm.notif == 'PRICE_INCREASE' ? _c('span', {
    staticClass: "badge badge-pill"
  }, [_c('span', {
    staticClass: "badge badge-pill bg-success"
  }, [_vm._v(" PRICE INCREASE ")])]) : _vm._e(), _vm.notif == 'REFUND' ? _c('span', {
    staticClass: "badge badge-pill"
  }, [_c('span', {
    staticClass: "badge badge-pill bg-purple text-light"
  }, [_vm._v(" REFUND ")])]) : _vm._e(), _vm.notif == 'REFUND_DECLINED' ? _c('span', {
    staticClass: "badge badge-pill"
  }, [_c('span', {
    staticClass: "badge badge-pill bg-yellow"
  }, [_vm._v(" REFUND DECLINED ")])]) : _vm._e(), _vm.notif == 'SUBSCRIBED' ? _c('span', {
    staticClass: "badge badge-pill"
  }, [_c('span', {
    staticClass: "badge badge-pill bg-primary text-light"
  }, [_vm._v(" SUBSCRIBED ")])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }