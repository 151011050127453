<template>
  <div class="text-black">
    <span v-if="notif == 'EXPIRED'" class="badge badge-pill">
      <span class="badge badge-pill bg-secondary">
        EXPIRED
      </span>
    </span>
    <span v-if="notif == 'DID_RENEW'" class="badge badge-pill">
      <span class="badge badge-pill bg-info">
        DID RENEW
      </span>
    </span>
    <span v-if="notif == 'DID_CHANGE_RENEWAL_STATUS'" class="badge badge-pill">
      <span class="badge badge-pill bg-warning">
        DID CHANGE RENEWAL STATUS
      </span>
    </span>
    <span v-if="notif == 'DID_FAIL_TO_RENEW'" class="badge badge-pill">
      <span class="badge badge-pill bg-danger text-light">
        DID FAIL TO RENEW
      </span>
    </span>
    <span v-if="notif == 'DID_CHANGE_RENEWAL_PREF'" class="badge badge-pill">
      <span class="badge badge-pill bg-orange">
        DID CHANGE RENEWAL PREF
      </span>
    </span>
    <span v-if="notif == 'PRICE_INCREASE'" class="badge badge-pill">
      <span class="badge badge-pill bg-success">
        PRICE INCREASE
      </span>
    </span>
    <span v-if="notif == 'REFUND'" class="badge badge-pill">
      <span class="badge badge-pill bg-purple text-light">
        REFUND
      </span>
    </span>
    <span v-if="notif == 'REFUND_DECLINED'" class="badge badge-pill">
      <span class="badge badge-pill bg-yellow">
        REFUND DECLINED
      </span>
    </span>
    <span v-if="notif == 'SUBSCRIBED'" class="badge badge-pill">
      <span class="badge badge-pill bg-primary text-light">
        SUBSCRIBED
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'notificationType',
  props: {
    notif: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped>
.badge {
  color: black;
  font-size: 10px;
}

.bg-orange {
  background-color: coral;
}
.bg-yellow {
  background-color: rgb(226, 233, 0);
}
.bg-purple {
  background-color: rgb(211, 0, 179);
}
</style>
