<template>
  <div class="row">
    <div class="col-md-12 mt-2">
      <div class="card">
        <h2 class="title text-center pt-1 mt-1">AppStore Notification</h2>
        <br />
        <b-row v-if="isError" class="justify-content-center mt-2 mx-3">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <div class="row justify-content-start mb-2">
            <span class="col-sm-2">Search Keyword</span>
            <div class="col-sm-4">
              <input
                v-model="keyword"
                type="text"
                class="form-control"
                placeholder="original transaction OR transaction id..."
                @input="debounceSearch"
              />
              <span v-if="typing"> <em> searching... </em> </span>
            </div>
          </div>
          <div class="row justify-content-start mb-2 form-inline">
            <span class="col-sm-2">Select Range</span>
            <div class="col-sm-6">
              <v-date-picker
                v-model="range"
                mode="date"
                :masks="masks"
                is-range
                @input="dateOnChange"
              >
                <template v-slot="{ inputValue, inputEvents, isDragging }">
                  <input
                    id="startPeriod"
                    class="form-control mr-1"
                    :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                    :value="inputValue.start"
                    v-on="inputEvents.start"
                  />
                  <input
                    id="endPeriod"
                    class="form-control"
                    :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                    :value="inputValue.end"
                    v-on="inputEvents.end"
                    :max-date="new Date()"
                  />
                </template>
              </v-date-picker>
            </div>
          </div>
          <div class="row justify-content-start mb-2">
            <span class="col-sm-2">Select Notification Type</span>
            <div class="col-sm-4">
              <b-form-select
                v-model="notification_type"
                :options="notificationTypeOptions"
                required
                @change="notifTypeOnChange"
              ></b-form-select>
            </div>
          </div>
          <div class="row justify-content-end">
            <label for="" class="col-sm-auto">Total : </label>
            <strong class="col-sm-auto">{{ formatNumber(totalRows) }}</strong>
          </div>
          <b-table
            sticky-header="500px"
            :items="items"
            :fields="fields"
            :busy="isLoading"
            :isLoading="isLoading"
            show-empty
            responsive="xl"
            hover
            sort-icon-left
          >
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1) }}.
            </template>

            <template #cell(product_id)="row">
              <div style="width: 350px">
                {{ row.item.product_id ? row.item.product_id : '-' }}
              </div>
            </template>

            <template #cell(environment)="row">
              <div
                style="width: 150px"
                v-if="row.item.environment == 'Production'"
              >
                <span class="badge badge-pill bg-primary">{{
                  row.item.environment ? row.item.environment : '-'
                }}</span>
              </div>
              <div
                style="width: 150px"
                v-else-if="row.item.environment == 'Sandbox'"
              >
                <span class="badge badge-pill bg-warning">{{
                  row.item.environment ? row.item.environment : '-'
                }}</span>
              </div>
              <div v-else>-</div>
            </template>

            <template #cell(notification_type)="row">
              <div style="width: 180px">
                <div v-if="!row.item.notification_type">-</div>
                <div v-else>
                  <notificationTypeVue :notif="row.item.notification_type" />
                </div>
              </div>
            </template>

            <template #cell(original_transaction_id)="row">
              <div style="width: 200px">
                {{
                  row.item.original_transaction_id
                    ? row.item.original_transaction_id
                    : '-'
                }}
              </div>
            </template>

            <template #cell(transaction_id)="row">
              <div
                style="width: 200px; cursor: pointer; text-decoration: none"
                v-if="row.item?.transaction_id"
              >
                <router-link
                  :to="{
                    path: '/appstore-notification/' + row.item?.transaction_id,
                  }"
                  target="_blank"
                >
                  {{ row.item?.transaction_id }}
                </router-link>
              </div>
              <div v-else>-</div>
            </template>

            <template #cell(purchase_date)="row">
              <div style="width: 250px">
                {{
                  row.item.purchase_date
                    ? formatDate(+row.item.purchase_date)
                    : '-'
                }}
              </div>
            </template>

            <template #cell(revocation_date)="row">
              <div style="width: 250px">
                {{
                  row.item.revocation_date && row.item.revocation_date != 0
                    ? formatDate(+row.item.revocation_date)
                    : '-'
                }}
              </div>
            </template>

            <template #cell(created)="row">
              <div style="width: 250px">
                {{ row.item?.created ? formatDate(row.item?.created) : '-' }}
              </div>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading ..
              </div>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import constant from '../../store/constant';
import notificationTypeVue from '../../components/appstore/notificationType.vue';
import moment from 'moment';
const notificationType = Object.entries(
  constant.APPSTRORE_NOTIFICATION_TYPE,
).map((val) => ({
  text: val[0].replace(/_/g, ' '),
  value: val[1],
}));
const notificationTypeSelected = [
  {
    text: 'All Notification Type',
    value: '',
  },
];
export default {
  name: 'ListAppstoreNotification',
  components: {
    notificationTypeVue,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      typing: false,
      keyword: null,
      notification_type: '',
      notificationTypeOptions: [
        ...notificationTypeSelected,
        ...notificationType,
      ],
      fields: [
        { key: 'No', label: 'No.' },
        { key: 'product_id', label: 'Product ID' },
        { key: 'environment', label: 'environment' },
        { key: 'notification_type', label: 'Notification Type' },
        { key: 'original_transaction_id', label: 'Original Transaction' },
        { key: 'transaction_id', label: 'Transaction ID' },
        { key: 'purchase_date', label: 'Purchase Date' },
        { key: 'revocation_date', label: 'Revocation Date' },
        { key: 'created', label: 'Created' },
      ],
      range: {
        start: moment()
          .subtract(1, 'day')
          .format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      },
      masks: {
        input: 'YYYY-MM-DD',
      },
    };
  },
  watch: {
    currentPage: function() {
      this.getAppstoreNotification();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Appstore Notification | Content Management System Ebooks Gramedia.com';
      },
    },

    items: function() {
      if (!this.items.length) return;
    },

    ErrorMessage: function() {
      if (!this.ErrorMessage) return;
      this.alertMsg(this.ErrorMessage, 'error');
    },
  },

  computed: {
    ...mapState({
      isLoading: (state) => state.appstore.isLoading,
      isError: (state) => state.appstore.isError,
      ErrorMessage: (state) => state.appstore.ErrorMessage,
      totalRows: (state) => state.appstore.totalRows,
      items: (state) => state.appstore.items,
    }),
  },

  mounted() {
    this.getAppstoreNotification();
  },

  methods: {
    ...mapActions('appstore', ['fetchAppstoreNotification']),

    getAppstoreNotification() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
      };
      if (this.keyword) payload.q = this.keyword;
      if (this.notification_type)
        payload.notification_type = this.notification_type;
      if (this.range.start && this.range.end) {
        payload.startDate = moment(this.range.start).format('YYYY-MM-DD');
        payload.endDate = moment(this.range.end).format('YYYY-MM-DD');
      }
      this.fetchAppstoreNotification(payload);
    },

    formatDate(tgl) {
      return new Date(tgl).toString();
    },

    formatNumber(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return '0';
    },

    debounceSearch(event) {
      this.typing = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = false;
        this.message = event.target.value;
        let payload = {
          page: this.currentPage,
          limit: this.perPage,
          q: this.keyword,
        };
        if (this.keyword) payload.q = this.keyword;
        if (this.notification_type)
          payload.notification_type = this.notification_type;
        this.fetchAppstoreNotification(payload);
      }, 600);
      this.range = {
        start: moment()
          .subtract(1, 'day')
          .format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      };
    },

    notifTypeOnChange() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
      };
      if (this.notification_type)
        payload.notification_type = this.notification_type;
      if (this.keyword) payload.q = this.keyword;
      if (this.range.start && this.range.end) {
        payload.startDate = moment(this.range.start).format('YYYY-MM-DD');
        payload.endDate = moment(this.range.end).format('YYYY-MM-DD');
      }
      this.fetchAppstoreNotification(payload);
    },

    dateOnChange() {
      let payload = {
        page: 1,
        limit: this.perPage,
        startDate: moment(this.range.start).format('YYYY-MM-DD'),
        endDate: moment(this.range.end).format('YYYY-MM-DD'),
      };
      if (this.notification_type)
        payload.notification_type = this.notification_type;
      if (this.keyword) payload.q = this.keyword;
      this.fetchAppstoreNotification(payload);
    },

    // routerDetail(transaction_id) {
    //   return this.$router.push('/appstore-notification/' + transaction_id);
    // },

    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },
  },
};
</script>
<style scoped>
input::placeholder {
  font-style: italic;
}
.badge-purple {
  background-color: #ff06dc;
}
.badge-yellow {
  background-color: #ffec2e;
  color: black;
}
</style>
